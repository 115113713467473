// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "#fQQOPOOO]OQO'#C`ObOSO'#CdOOOO'#C^'#C^OOOO'#Ch'#ChQQOPOOOgOQO,58zOlOQO,59OOOOO-E6f-E6fOOOO1G.f1G.fOqOQO1G.jOvOPO7+$UO!UOSO<<GpO!ZOPO<<GpO!iOQOAN=[O!nOSOAN=[OOOOG22vG22vO!sOQOG22vOOOOLD(bLD(b",
  stateData: "!}~ORROTPOXQO~OUUO~OYVO~OVXO~OUYO~OVZO~ORROTPOXQOZ[O~OY^O~ORROTPOXQOZ_O~OV`O~OYaO~OVbO~OXTRT~",
  goto: "t]PP^PdPPPdPPPjXSOTZ]XROTZ]QTOSWT]R]Z",
  nodeNames: "⚠ Template TemplateElement Text ExpressionDirective ExpressionDirectiveOpenTag BlockDirectiveContent DirectiveTagEnd BlockDirective BlockDirectiveOpenTagStart BlockName BlockDirectiveCloseTagStart",
  maxTerm: 13,
  skippedNodes: [0],
  repeatNodeCount: 1,
  tokenData: "*hVRYO!cq!c!}%}!}#Tq#T#o%}#o#p&v#p#qq#q#r)f#r;'Sq;'S;=`%w<%lOqRxVUQRPO#oq#o#p!_#p#qq#q#r$|#r;'Sq;'S;=`%w<%lOqR!dVUQO#o!y#o#p#m#p#q!y#q#rq#r;'S!y;'S;=`#g<%lO!yR#QVUQRPO#o!y#o#p!_#p#q!y#q#rq#r;'S!y;'S;=`#g<%lO!yR#jP;=`<%l!yQ#rVUQO#o#m#o#p#m#p#q#m#q#r$X#r;'S#m;'S;=`$v<%lO#mQ$^UUQO#o$X#o#p#m#p#q$X#r;'S$X;'S;=`$p<%lO$XQ$sP;=`<%l$XQ$yP;=`<%l#mP%RTRPO#o$|#o#p%b#p;'S$|;'S;=`%q<%lO$|P%eSO#o$|#p;'S$|;'S;=`%q<%lO$|P%tP;=`<%l$|R%zP;=`<%lqV&WYUQYSRPO!cq!c!}%}!}#Tq#T#o%}#o#p!_#p#qq#q#r$|#r;'Sq;'S;=`%w<%lOqR&{VUQO#o!y#o#p'b#p#q!y#q#rq#r;'S!y;'S;=`#g<%lO!yR'iZUQTPOs#mst([t!P#m!P!Q(x!Q#o#m#o#p#m#p#q#m#q#r$X#r;'S#m;'S;=`$v<%lO#mR(cVUQXPO#o#m#o#p#m#p#q#m#q#r$X#r;'S#m;'S;=`$v<%lO#mR)PVZPUQO#o#m#o#p#m#p#q#m#q#r$X#r;'S#m;'S;=`$v<%lO#mR)kVRPO#o$|#o#p%b#p#q$|#q#r*Q#r;'S$|;'S;=`%q<%lO$|R*XTVQRPO#o$|#o#p%b#p;'S$|;'S;=`%q<%lO$|",
  tokenizers: [0, 1, 2],
  topRules: {"Template":[0,1]},
  tokenPrec: 86
})
